<template>
<div class="kt-form kt-form--label-right">
    <div class="kt-form__body">
        <div class="kt-section kt-section--first">
            <UserProfile />
        </div>
    </div>
</div>
</template>

<script lang="ts">
import userMixins from '../../store/mixins/userMixins';
import UserProfile from './UserProfile.vue';

export default {
    name: 'MyProfile',
    components: {
        UserProfile,
    },
    mixins: [userMixins],
};
</script>
