<template>
<div class="kt-section__body">
    <GoogleClassroomConnect v-if="!$_userMixins_isSuperAdmin" />
</div>
</template>

<script lang="ts">
import GoogleClassroomConnect from '../../components/GoogleClassroomConnect.vue';
import userMixins from '../../store/mixins/userMixins';

export default {
    name: 'UserProfile',
    components: { GoogleClassroomConnect },
    mixins: [userMixins],
};
</script>
