var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kt-section__body" },
    [!_vm.$_userMixins_isSuperAdmin ? _c("GoogleClassroomConnect") : _vm._e()],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }